<template>
  <v-container>
    <v-row align="center" justify="center" class="pt-5">
      <v-card elevation="0" width="1024" class="mb-5 transparent" :loading="isLoading">
        <v-toolbar flat class="mb-4 transparent">
          <h1 class="headline black--text">{{ $t('documents.upload_document') }}</h1>
          <v-spacer />
          <v-btn class="primary--text" depressed to="/patients/documents">{{ $t('common.cancel') }}</v-btn>
        </v-toolbar>
        <v-card class="text-center">
          <v-card-text v-if="!document.selected">
            <d-upload-file :document="document" />
          </v-card-text>
          <v-card-text v-else>
            <div class="mb-5">
              <v-icon size="140" color="grey lighten-2">{{ document.typeIcon }}</v-icon>
            </div>
            <v-row justify="center">
              <v-col cols="5">
                <v-autocomplete
                  v-model="document.professional"
                  filled
                  chips
                  color="primary"
                  :label="$t('calendario.select_professional')"
                  :disabled="isLoading"
                  :items="professionals"
                  item-text="name"
                  item-value="id"
                  :outlined="true"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeProfessional(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.image" />
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img :src="data.item.image" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.group }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="mb-5">{{ $t('documents.are_you_sure_upload') }}</div>
                <v-btn class="grey--text mr-1" text @click="reset">{{ $t('common.cancel') }}</v-btn>
                <v-btn color="primary" :disabled="isLoading" depressed @click="confirmUpload">{{
                  $t('common.confirm')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import { displayNameEnv, getDocumentsUrl, getProfessionalsUrl, getHeader, urlPatient } from '../../config/config';
import DUploadFile from '../../components/DUploadFile.vue';

export default {
  components: { DUploadFile },
  title: displayNameEnv + ' - ' + vm.$t('documents.upload_document'),
  data() {
    return {
      isLoading: false,
      document: {
        selected: false,
        uploaded: false,
        selecting: false,
        file: '',
        base64: '',
        typeIcon: 'mdi-text-box-check-outline',
        sizeMax: 20,
        professional: 0,
      },
      professionals: [],
    };
  },
  mounted() {
    this.loadProfessionals();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    confirmUpload() {
      this.uploadDocumentBase64(this.document.file);
    },

    uploadDocumentBase64(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.document.base64 = e.target.result;
        this.uploadDocument();
      };
      reader.readAsDataURL(file);
    },

    uploadDocument() {
      this.isLoading = true;
      const param = {
        professional: this.document.professional,
        name: this.document.file.name,
        content: this.document.base64,
      };
      this.$http
        .post(urlPatient(getDocumentsUrl, 0, 0), param, { headers: getHeader() })
        .then(() => {
          this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('documentos.subido_correctamente') });
          this.document.uploaded = true;
          this.document.selected = false;
        })
        .catch(err => {
          this.$log.error(err);
          let msg = 'Ocurrió un error!';
          if (err && err.response && err.response.data) {
            msg = err.response.data.detail;
          }
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadProfessionals() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(getProfessionalsUrl, 0, 0), { headers: getHeader() })
        .then(res => {
          this.professionals = res.body;
        })
        .catch(err => {
          this.$log.error(err);
          let msg = 'Ocurrió un error!';
          if (err && err.response && err.response.data) {
            msg = err.response.data.detail;
          }
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    removeProfessional() {
      this.document.professional = 0;
    },

    reset() {
      this.document.file = '';
      this.document.selected = false;
      this.document.uploaded = false;
      this.document.professional = 0;
      this.document.base64 = '';
    },
  },
};
</script>
